export default `<style>
    [data-custom-class='body'], [data-custom-class='body'] * {
        background: transparent !important;
    }

    [data-custom-class='title'], [data-custom-class='title'] * {
        font-family: Arial !important;
        font-size: 26px !important;
        color: #000000 !important;
    }

    [data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
        font-family: Arial !important;
        color: #595959 !important;
        font-size: 14px !important;
    }

    [data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
        font-family: Arial !important;
        font-size: 19px !important;
        color: #000000 !important;
    }

    [data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
        font-family: Arial !important;
        font-size: 17px !important;
        color: #000000 !important;
    }

    [data-custom-class='body_text'], [data-custom-class='body_text'] * {
        color: #595959 !important;
        font-size: 14px !important;
        font-family: Arial !important;
    }

    [data-custom-class='link'], [data-custom-class='link'] * {
        color: #3030F1 !important;
        font-size: 14px !important;
        font-family: Arial !important;
        word-break: break-word !important;
    }
</style>

<div data-custom-class="body">
  <div><strong><span style="font-size: 26px;"><span data-custom-class="title">PRIVACY NOTICE</span></span></strong>
  </div>
  <div><br></div>
  <div><span style="color: rgb(127, 127, 127);"><strong><span style="font-size: 15px;"><span
    data-custom-class="subtitle">Last updated <bdt
    class="question">February 04, 2021</bdt></span></span></strong></span></div>
  <div><br></div>
  <div><br></div>
  <div><br></div>
  <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text">Thank you for choosing to be part of our community at <bdt
    class="question">Riskeeper AG</bdt><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
    class="block-component"></bdt></span></span> ("<span style="color: rgb(89, 89, 89);"><span
    data-custom-class="body_text"><bdt class="block-component"></bdt></span></span><strong>Company</strong></span><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><span
    data-custom-class="body_text"></span></bdt></span></span></span></span></span><span data-custom-class="body_text">", "<strong>we</strong>", "<strong>us</strong>", "<strong>our</strong>"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at <bdt
    class="question">contact@riskeeper.com</bdt>.</span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
    data-custom-class="body_text">When you <span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
    class="block-component"></bdt></span></span>visit our website <bdt class="question"><a href="https://riskeeper.com"
                                                                                           target="_blank"
                                                                                           data-custom-class="link">https://riskeeper.com</a></bdt> (the "<strong>Website</strong>"), <span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt><bdt
    class="block-component"></bdt><bdt class="block-component"></bdt></span></span> and more generally, use any of our services (the "<strong>Services</strong>", which include the </span><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
    data-custom-class="body_text"><bdt class="block-component"><span
    data-custom-class="body_text"></span></bdt>Website<span style="color: rgb(89, 89, 89);"><span
    data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span><bdt
    class="block-component"></bdt></span></span></span><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
    class="block-component"></span></bdt></span></span></span></span><span data-custom-class="body_text">), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.</span></span></span>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text">This privacy notice applies to all information collected through our Services (which, as described above, includes our </span><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="block-component"><span
    data-custom-class="body_text"></span></bdt>Website<span style="color: rgb(89, 89, 89);"><span
    data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span><bdt
    class="block-component"><bdt class="block-component"></span></bdt></span></span></span></span><span
    data-custom-class="body_text">), as well as, any related services, sales, marketing or events.</span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</strong></span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(0, 0, 0);"><strong><span
    data-custom-class="heading_1">TABLE OF CONTENTS</span></strong></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><br></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infocollect"><span
    style="color: rgb(89, 89, 89);">1. WHAT INFORMATION DO WE COLLECT?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infouse"><span
    style="color: rgb(89, 89, 89);">2. HOW DO WE USE YOUR INFORMATION?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
    data-custom-class="link" href="#infoshare">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</a></span><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
    data-custom-class="link" href="#whoshare">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</a></span><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span><bdt
    class="block-component"></bdt></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#cookies"><span
    style="color: rgb(89, 89, 89);">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt><span style="color: rgb(89, 89, 89);"><bdt
    class="block-component"></bdt><bdt class="block-component"></bdt></span></span><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#inforetain"><span
    style="color: rgb(89, 89, 89);">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><bdt
    class="block-component"></bdt></span></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infosafe"><span
    style="color: rgb(89, 89, 89);">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt><bdt
    class="block-component"></bdt></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#infominors"><span
    style="color: rgb(89, 89, 89);">8. DO WE COLLECT INFORMATION FROM MINORS?</span></a><span
    style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
    style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><a
    data-custom-class="link" href="#privacyrights">9. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#DNT"><span
    style="color: rgb(89, 89, 89);">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#caresidents"><span
    style="color: rgb(89, 89, 89);">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px;"><a data-custom-class="link" href="#policyupdates"><span
    style="color: rgb(89, 89, 89);">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
  <div style="line-height: 1.5;"><a data-custom-class="link" href="#contact"><span
    style="color: rgb(89, 89, 89); font-size: 15px;">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
  <div style="line-height: 1.5;"><a data-custom-class="link" href="#request"><span style="color: rgb(89, 89, 89);">14. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</span></a>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div id="infocollect" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control" style="color: rgb(0, 0, 0);"><strong><span
    data-custom-class="heading_1">1. WHAT INFORMATION DO WE COLLECT?</span></strong><span data-custom-class="heading_1"><bdt
    class="block-component"><span data-custom-class="body_text"></span></bdt></span></span></span></span></span></span>
  </div>
  <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
    style="font-size: 15px;"><strong><u><br></u></strong><strong>Personal information you disclose to us</strong></span></span>
  </div>
  <div>
    <div><br></div>
    <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
      style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      data-custom-class="body_text"><strong><em>In Short: </em></strong></span></span></span></span><span
      data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span
      data-custom-class="body_text"><strong><em> </em></strong><em>We collect personal information that you provide to us.</em></span></span></span></span></span></span>
    </div>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We collect personal information that you voluntarily provide to us when you <span
    style="font-size: 15px;"><bdt class="block-component"></bdt></span></span><span data-custom-class="body_text">express an interest in obtaining information about us or our products and Services, when you participate in activities on the <span
    style="font-size: 15px;"><bdt class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
    class="block-component"></bdt><bdt class="block-component"></bdt></span><bdt
    class="block-component"></bdt></span></span><span
    data-custom-class="body_text"> or otherwise when you contact us.</span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">The personal information that we collect depends on the context of your interactions with us and the <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span>, the choices you make and the products and features you use. The personal information we collect may include the following:<bdt
    class="block-component"></bdt></span></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Personal Information Provided by You.</strong> We collect <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="forloop-component"></bdt><bdt
    class="question">names</bdt>; </span><span data-custom-class="body_text"><bdt class="forloop-component"></bdt><bdt
    class="question">email addresses</bdt>; </span><span data-custom-class="body_text"><bdt
    class="forloop-component"></bdt><bdt class="question">company name</bdt>; </span><span
    data-custom-class="body_text"><bdt class="forloop-component"></bdt>and other similar information.</span><span
    data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt><bdt class="block-component"><bdt
    class="block-component"></bdt></bdt></span></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.<span
    style="font-size: 15px;"><bdt class="statement-end-if-in-editor"><bdt
    class="statement-end-if-in-editor"></bdt></bdt></span><bdt class="block-component"></bdt></span></span></span></div>
  <div style="line-height: 1.5;"><span data-custom-class="heading_2" style="color: rgb(0, 0, 0);"><span
    style="font-size: 15px;"><strong><u><br></u></strong><strong>Information automatically collected</strong></span></span>
  </div>
  <div>
    <div><br></div>
    <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
      style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      data-custom-class="body_text"><strong><em>In Short: </em></strong></span></span></span></span><span
      data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span
      data-custom-class="body_text"><strong><em> </em></strong><em>Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our <span
      data-custom-class="body_text"><em><span data-custom-class="body_text"><span style="font-size: 15px;"><span
      data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
      class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
      class="block-component"></bdt></span></span></span></em></span>.</em></span></span></span></span></span></span>
    </div>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We automatically collect certain information when you visit, use or navigate the <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span></span>. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span></span> and other technical information. This information is primarily needed to maintain the security and operation of our <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span></span>, and for our internal analytics and reporting purposes.<bdt
    class="block-component"></bdt></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Like many businesses, we also collect information through cookies and similar technologies. <bdt
    class="block-component"></bdt><bdt class="block-component"></bdt><bdt class="statement-end-if-in-editor"><span
    data-custom-class="body_text"></span></bdt><bdt class="block-component"></bdt></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">The information we collect includes:<bdt
    class="block-component"></bdt></span></span></span></div>
  <ul>
    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our <span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
      class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
      class="block-component"></bdt></span></span></span></span></span> and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the <span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
      class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
      class="block-component"></bdt></span></span></span></span> </span>(such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).<span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li>
  </ul>
  <div>
    <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
  </div>
  <ul>
    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Device Data.</em> We collect device data such as information about your computer, phone, tablet or other device you use to access the <span
      style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
      class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
      class="block-component"></bdt></span></span>. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.<span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li>
  </ul>
  <div>
    <bdt class="block-component"><span style="font-size: 15px;"></span></bdt>
  </div>
  <ul>
    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><em>Location Data.</em> We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the <span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
      class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
      class="block-component"></bdt></span></span></span></span></span>. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.<span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></li>
  </ul>
  <div>
    <bdt class="block-component"><span style="font-size: 15px;"></bdt>
    </bdt>
    <bdt class="statement-end-if-in-editor"></bdt>
    <span data-custom-class="body_text"><span style="font-size: 15px;"><bdt class="statement-end-if-in-editor"><bdt
      class="statement-end-if-in-editor"><span style="font-size: 15px;"><span data-custom-class="body_text"><span
      style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="statement-end-if-in-editor"><bdt
      class="statement-end-if-in-editor"><bdt class="block-component"></bdt></bdt></span></span></span></span><span
      style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
      style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
      class="statement-end-if-in-editor"><bdt
      class="block-component"></bdt></bdt></span></span></span></span></bdt></span></span>
    <bdt class="block-component"></bdt>
    </span></span></span></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div id="infouse" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
    style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control" style="color: rgb(0, 0, 0);"><strong><span
    data-custom-class="heading_1">2. HOW DO WE USE YOUR INFORMATION?</span></strong></span></span></span></span></span>
  </div>
  <div>
    <div style="line-height: 1.5;"><br></div>
    <div style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
      style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      data-custom-class="body_text"><strong><em>In Short:  </em></strong><em>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</em></span></span></span></span></span></span>
    </div>
  </div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We use personal information collected via our <span
    style="font-size: 15px;"><span data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
    class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
    class="block-component"></bdt></span></span> for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.<bdt
    class="block-component"></bdt></span></span></span></div>
  <div style="line-height: 1.5;"><br></div>
  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We use the information we collect or receive:<bdt
    class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></bdt></span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
    <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
      class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
      <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
        style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
        class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
        <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
          style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
          class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
          <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
            class="block-component"></bdt></span></span></span></li></ul>
            <div>
              <bdt class="block-component"></bdt>
            </div>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To send administrative information to you. </strong>We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.<span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span>
              </li>
            </ul>
            <div>
              <bdt class="block-component"></bdt>
            </div>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To protect our Services. </strong>We may use your information as part of our efforts to keep our <span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                class="block-component"></bdt><bdt
                class="block-component"></bdt></span></span></span></span></span></span> safe and secure (for example, for fraud monitoring and prevention).<span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <div>
              <bdt class="block-component"></bdt>
            </div>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.<span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="font-size: 15px; color: rgb(89, 89, 89);"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></strong></span></span></span>
              </li>
            </ul>
            <div>
              <bdt class="block-component"></bdt>
            </div>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To respond to legal requests and prevent harm. </strong>If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.<span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <p style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </p>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Fulfill and manage your orders. </strong>We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the <span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                class="block-component"></bdt><bdt
                class="block-component">.</bdt></span></span></span></span></span></span><span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <p style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </p>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Administer prize draws and competitions.</strong> We may use your information to administer prize draws and competitions when you elect to participate in our competitions.<span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <p style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </p>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To deliver and facilitate delivery of services to the user.</strong> We may use your information to provide you with the requested service.<span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <p style="font-size: 15px;">
              <bdt class="block-component"></bdt>
            </p>
            <ul>
              <li style="line-height: 1.5;"><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>To respond to user inquiries/offer support to users.</strong> We may use your information to respond to your inquiries and solve any potential issues you might have with the use of our Services.<span
                style="color: rgb(89, 89, 89);"><span style="color: rgb(89, 89, 89);"><span
                data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span></span></span>
              </li>
            </ul>
            <div>
              <bdt class="block-component"><span style="font-size: 15px;"></bdt>
              </span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
              <div>
                <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                </span></span></span></li></ul>
                <div>
                  <bdt class="block-component"><span style="font-size: 15px;"></bdt>
                  </span></span></span></li></ul>
                  <div><span style="font-size: 15px;"><bdt class="block-component"></bdt></span>
                    <bdt class="statement-end-if-in-editor"><span style="font-size: 15px;"></span></bdt>
                  </div>
                  <div style="line-height: 1.5;"><br></div>
                  <div id="infoshare" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                    style="color: rgb(89, 89, 89); font-size: 15px;"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                           style="color: rgb(0, 0, 0);"><strong><span
                    data-custom-class="heading_1">3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span></strong></span></span></span></span></span>
                  </div>
                  <div style="line-height: 1.5;"><br></div>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong><em>In Short:</em></strong><em>  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</em></span></span></span>
                  </div>
                  <div style="line-height: 1.5;"><br></div>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We may process or share your data that we hold based on the following legal basis:<bdt
                    class="block-component"></bdt></span></span></span></div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Consent:</strong> We may process your data if you have given us specific consent to use your personal information for a specific purpose.<span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></li>
                  </ul>
                  <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="statement-end-if-in-editor"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="block-component"></bdt></span></span></span></bdt></span></span></span></span></span></span>
                  </div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Legitimate Interests:</strong> We may process your data when it is reasonably necessary to achieve our legitimate business interests.<span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span>
                    </li>
                  </ul>
                  <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="block-component"></bdt></span></span></span></span></span></span></div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Performance of a Contract:</strong> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.<span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span>
                    </li>
                  </ul>
                  <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="block-component"></bdt></span></span></span></span></span></span></div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Legal Obligations:</strong> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).<span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span>
                    </li>
                  </ul>
                  <div><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                    class="block-component"></bdt></span></span></span></span></span></span></div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Vital Interests:</strong> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.<span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span></span>
                    </li>
                  </ul>
                  <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                    style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">More specifically, we may need to process your data or share your personal information in the following situations:</span></span></span>
                  </div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Business Transfers.</strong> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.</span></span></span>
                    </li>
                  </ul>
                  <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span></div>
                  <ul>
                    <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                      style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><strong>Vendors, Consultants and Other Third-Party Service Providers.</strong> We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the <span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                      class="block-component"></bdt><bdt
                      class="block-component"></bdt></span></span></span></span></span></span>, which will enable them to collect data on our behalf about how you interact with our <span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                      class="block-component"></bdt><bdt
                      class="block-component"></bdt></span></span></span></span></span></span> over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes. <span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></span>We have contracts in place with our data processors, which are designed to help safeguard your personal information. This means that they cannot do anything with your personal information unless we have instructed them to do it. They will also not share your personal information with any organization apart from us. They also commit to protect the data they hold on our behalf and to retain it for the period we instruct.<span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span><span
                      data-custom-class="body_text"><span style="font-size: 15px;"><span
                      style="color: rgb(89, 89, 89);"><bdt
                      class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span>
                    </li>
                  </ul>
                  <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                    data-custom-class="body_text"><bdt class="block-component"></bdt></span><span
                    data-custom-class="body_text"><bdt class="block-component"><span style="font-size: 15px;"><span
                    style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                    style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
                    class="statement-end-if-in-editor"></bdt></span></span></span></span></span></bdt></span></span></span></span></span></span></span></span></li></ul>
                    <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                      data-custom-class="body_text"><bdt
                      class="block-component"></bdt></span></span></span></span></span></span></span></span></li></ul>
                      <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                        data-custom-class="body_text"><bdt
                        class="block-component"></bdt></span></span></span></span></span></li></ul>
                        <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                          data-custom-class="body_text"><bdt
                          class="block-component"></bdt></span></span></span></span></span></span></span></span></li></ul>
                          <div><span style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            style="font-size: 15px;"><span data-custom-class="body_text"><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span></span></li></ul>
                            <div style="line-height: 1.5;"><span style="color: rgb(89, 89, 89);"><bdt
                              class="block-component"></bdt></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="whoshare" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">4. WHO WILL YOUR INFORMATION BE SHARED WITH?</span></strong> </span> </span> </span> </span> </span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short:</em></strong><em>  We only share information with the following third parties.</em></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We only share and disclose your information with the following third parties. We have categorized each party so that you may be easily understand the purpose of our data collection and processing practices. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "<a
                              data-custom-class="link" href="#contact"><span style="color: rgb(89, 89, 89);">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a>".<bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                            </div>
                            <ul>
                              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><strong>Cloud Computing Services</strong></span></span></span>
                              </li>
                            </ul>
                            <div style="margin-left: 40px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
                              class="forloop-component"><span data-custom-class="body_text"></span></bdt><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt><bdt class="question">Google Cloud Platform</bdt><bdt
                              class="block-component"></bdt></span></span></span></span><bdt
                              class="forloop-component"></bdt><bdt
                              class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                            </div>
                            <ul>
                              <li style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                style="font-size: 15px; color: rgb(89, 89, 89);"><span
                                data-custom-class="body_text"><strong>Web and Mobile Analytics</strong></span></span></span>
                              </li>
                            </ul>
                            <div style="margin-left: 40px;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
                              class="forloop-component"><span data-custom-class="body_text"></span></bdt><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><bdt class="block-component"></bdt><bdt class="question">Google Analytics</bdt><bdt
                              class="block-component"></bdt></span></span></span></span><bdt
                              class="forloop-component"></bdt><bdt
                              class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span></span></span></span><span
                                data-custom-class="body_text"></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"><bdt
                                class="block-component"></bdt></span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"></span><span data-custom-class="body_text"></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"></span><span data-custom-class="body_text"></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span>
                              <bdt class="block-component"></bdt>
                              </span></span></span></span></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"></span><span data-custom-class="body_text"></span><span
                                data-custom-class="body_text"><bdt class="statement-end-if-in-editor"></bdt></span>
                            </div>
                            <div><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
                              class="block-component"><span
                              data-custom-class="heading_1"></span></bdt></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="cookies" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short: </em></strong><em> We may use cookies and other tracking technologies to collect and store your information.</em></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice<span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt>.</span><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="statement-end-if-in-editor"></bdt></span><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt><bdt class="block-component"></bdt></span><bdt
                              class="block-component"><span data-custom-class="body_text"><bdt
                              class="block-component"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="block-component"></span></bdt></span></span></span></span></span></span></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="inforetain" style="line-height: 1.5;"><span
                              style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">6. HOW LONG DO WE KEEP YOUR INFORMATION?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short: </em></strong><em> We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</em></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than <span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><bdt class="block-component"></bdt><bdt class="question">6 months</bdt><bdt
                              class="statement-end-if-in-editor"></bdt></span></span></span>.</span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.<span
                              style="color: rgb(89, 89, 89);"><bdt
                              class="block-component"></bdt></span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="infosafe" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short: </em></strong><em> We aim to protect your personal information through a system of organizational and technical security measures.</em></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our <span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                              class="block-component"></bdt><bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span> is at your own risk. You should only access the <span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                              class="block-component"></bdt><bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span> within a secure environment.<span
                              style="color: rgb(89, 89, 89);"><bdt class="statement-end-if-in-editor"></bdt></span><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="infominors" style="line-height: 1.5;"><span
                              style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">8. DO WE COLLECT INFORMATION FROM MINORS?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short:</em></strong><em>  We do not knowingly collect data from or market to children under 18 years of age.</em></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We do not knowingly solicit data from or market to children under 18 years of age. By using the <span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                              class="block-component"></bdt><bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span>, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the <span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><span
                              style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt>Website<bdt class="statement-end-if-in-editor"></bdt><bdt
                              class="block-component"></bdt><bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span>. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at <span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                              class="block-component"></bdt><bdt class="question">contact@riskeeper.com</bdt><bdt
                              class="else-block"></bdt></span></span>.<span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                              class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div id="privacyrights" style="line-height: 1.5;"><span
                              style="color: rgb(127, 127, 127);"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                     style="color: rgb(0, 0, 0);"><strong><span
                              data-custom-class="heading_1">9. WHAT ARE YOUR PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><strong><em>In Short:</em></strong><em>  <span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                              data-custom-class="body_text"><em><bdt
                              class="block-component"></bdt></em></span></span></span>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control over your personal information. <span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                              data-custom-class="body_text"><em><bdt
                              class="statement-end-if-in-editor"></bdt></em></span></span></span>You may review, change, or terminate your account at any time.</em><span
                              style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt
                              class="block-component"></bdt></span></span></span></span></span></div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">In some regions (like the European Economic Area), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; and (iv) if applicable, to data portability. In certain circumstances, you may also have the right to object to the processing of your personal information. To make such a request, please use the <a
                              data-custom-class="link" href="#contact"><span style="font-size: 15px;"><span
                              style="color: rgb(48, 48, 241);">contact details</span></span></a> provided below. We will consider and act upon any request in accordance with applicable data protection laws.</span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If we are relying on your consent to process your personal information, you have the right to withdraw your consent at any time. Please note however that this will not affect the lawfulness of the processing before its withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.<span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                              style="font-size: 15px;"><bdt
                              class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"> </span></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details here: <span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="color: rgb(48, 48, 241);"><span
                              data-custom-class="body_text"><a data-custom-class="link"
                                                               href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                               rel="noopener noreferrer" target="_blank"><span
                              style="font-size: 15px;">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm</span></a></span></span></span></span></span>.</span></span></span>
                            </div>
                            <div style="line-height: 1.5;"><br></div>
                            <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                              style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are a resident in Switzerland, the contact details for the data protection authorities are available here: <span
                              style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                              data-custom-class="body_text"><span style="color: rgb(48, 48, 241);"><span
                              data-custom-class="body_text"><span style="font-size: 15px;"><a data-custom-class="link"
                                                                                              href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                              rel="noopener noreferrer"
                                                                                              target="_blank">https://www.edoeb.admin.ch/edoeb/en/home.html</a></span></span></span></span></span></span>.<bdt
                              class="block-component"></bdt><bdt
                              class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span>
                            </div>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our <span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
                            class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span>. To opt-out of interest-based advertising by advertisers on our <span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
                            class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span></span></span></span></span> visit <span
                            style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><a
                            data-custom-class="link" href="http://www.aboutads.info/choices/" rel="noopener noreferrer"
                            target="_blank"><span style="font-size: 15px;">http://www.aboutads.info/choices/</span></a></span></span>. <span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></span></span></span></span><span
                            data-custom-class="body_text"><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span></span></bdt>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div id="DNT" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                   style="color: rgb(0, 0, 0);"><strong><span
                            data-custom-class="heading_1">10. CONTROLS FOR DO-NOT-TRACK FEATURES</span></strong></span></span></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</span></span> </span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div id="caresidents" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                   style="color: rgb(0, 0, 0);"><strong><span
                            data-custom-class="heading_1">11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></strong></span></span></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><strong><em>In Short: </em></strong><em> Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</em></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the contact information provided below.</span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you are under 18 years of age, reside in California, and have a registered account with <span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt>the Website<bdt
                            class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span>, you have the right to request removal of unwanted data that you publicly post on the <span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
                            class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span>. To request removal of such data, please contact us using the contact information provided below, and include the email address associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the <span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt>Website<bdt
                            class="statement-end-if-in-editor"></bdt><bdt class="block-component"></bdt><bdt
                            class="block-component"></bdt></span></span></span></span></span></span></span>, but please be aware that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).<span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                            class="block-component"></span></bdt></span></span></span></span></span></span></span></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div id="policyupdates" style="line-height: 1.5;"><span
                            style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                   style="color: rgb(0, 0, 0);"><strong><span
                            data-custom-class="heading_1">12. DO WE MAKE UPDATES TO THIS NOTICE?</span></strong> </span> </span> </span> </span> </span>
                          </div>
                          <div style="line-height: 1.5;"><em><br></em></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><em><strong>In Short: </strong> Yes, we will update this notice as necessary to stay compliant with relevant laws.</em></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div id="contact" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                   style="color: rgb(0, 0, 0);"><strong><span
                            data-custom-class="heading_1">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></strong> </span> </span> </span> </span> </span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">If you have questions or comments about this notice, you may <span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span data-custom-class="body_text"><bdt
                            class="block-component"></bdt>email us at <bdt
                            class="question">contact@riskeeper.com</bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"> or by post to:</span></span></span></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><span style="color: rgb(89, 89, 89);"><span
                            style="color: rgb(89, 89, 89);"><span data-custom-class="body_text"><bdt class="question">Riskeeper AG</bdt></span></span></span></span></span><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                            data-custom-class="body_text"><bdt class="block-component"></bdt></span></span></bdt></span></span></span></span>
                          </div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><bdt class="question">Baarerstrasse 10</bdt><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt
                            class="block-component"></bdt></span></span></span></bdt></span></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><bdt class="question">Zug</bdt><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt> <bdt class="question">CH-6302</bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span><span style="font-size: 15px;"><bdt
                            class="block-component"></bdt><bdt class="block-component"></bdt></span></span></span></bdt></span>
                          </div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                            class="block-component"></bdt></span></span></span><bdt
                            class="question">Switzerland</bdt><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                            class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                            class="statement-end-if-in-editor"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span></bdt><bdt
                            class="statement-end-if-in-editor"></bdt></span></span></span><span
                            data-custom-class="body_text"><span style="color: rgb(89, 89, 89);"><span
                            style="font-size: 15px;"><bdt class="statement-end-if-in-editor"></bdt></span></span></span><span
                            style="color: rgb(89, 89, 89);"><span style="font-size: 15px;"><span
                            data-custom-class="body_text"><bdt class="block-component"><bdt
                            class="block-component"></bdt></span></span></div>
                          <div style="line-height: 1.5;"><br></div>
                          <div id="request" style="line-height: 1.5;"><span style="color: rgb(127, 127, 127);"><span
                            style="color: rgb(89, 89, 89); font-size: 15px;"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span id="control"
                                                                                   style="color: rgb(0, 0, 0);"><strong><span
                            data-custom-class="heading_1">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></strong> </span> </span> </span> </span> </span>
                          </div>
                          <div style="line-height: 1.5;"><br></div>
                          <div style="line-height: 1.5;"><span style="font-size: 15px; color: rgb(89, 89, 89);"><span
                            style="font-size: 15px; color: rgb(89, 89, 89);"><span data-custom-class="body_text">Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please <bdt
                            class="block-component"></bdt>submit a request form by clicking </span><span
                            style="color: rgb(48, 48, 241);"><span data-custom-class="body_text"><span
                            style="font-size: 15px;"><a data-custom-class="link"
                                                        href="mailto:contact@riskeeper.com"
                                                        rel="noopener noreferrer" target="_blank">here</a></span></span></span><bdt
                            class="block-component"><span data-custom-class="body_text"></bdt></span></span><span
                            data-custom-class="body_text">. We will respond to your request within 30 days.</span></span></span>
                          </div>
                          <style>
                              ul {
                                  list-style-type: square;
                              }

                              ul > li > ul {
                                  list-style-type: circle;
                              }

                              ul > li > ul > li > ul {
                                  list-style-type: square;
                              }

                              ol li {
                                  font-family: Arial;
                              }
                          </style>
                        </div>
                        <div style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;">This privacy
                          policy was created using <a style="color: rgb(48, 48, 241) !important;"
                                                      href="https://termly.io/products/privacy-policy-generator/?ftseo">Termly’s
                            Privacy Policy Generator</a>.
                        </div>
                        `;
