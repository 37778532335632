import React from 'react';
import Layout from 'components/Layout';
import { SliceContainer } from '../components/Shared';
import dataPrivacy from '../intl/data-privacy-statement';

export default () => {
  return (
    <Layout tabTitlePrefix={'Privacy Notice'} headerInverted>
      <SliceContainer first>
        <div dangerouslySetInnerHTML={{ __html: dataPrivacy }} />
      </SliceContainer>
    </Layout>
  );
};
